:root {
  --alternate-dark-color: #30a9a4;
  --alternate-light-color: #30a9a5c0;
  --text-dark-color: #040404;
  --text-light-color: #eee;
  --text-white-color: #fff;

  --text-border-color: rgba(255, 255, 255, 0.05);
  --text-border-color-hover: #30a9a59d;
  --text-border-color-focus: #30a9a4;
  --background-color: #204a5a;
  --placeholder: rgba(255, 255, 255, 0.2);
  --mycolor: #1b3e4d;
  --marketViewRow: #1e5164;
}

html > body > #root {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.material-icons-new {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
}

#spinning-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 15vmin;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  color: inherit;
}

.MuiFormLabel {
  color: red !important;
}

.ReactTable .rt-expander:after {
  border-top: 7px solid rgba(93, 117, 152, 1) !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  /* -webkit-appearance: none;  */
  /* color: red !important;
  margin: 0;
  
  background-color: red; */
  font-size: 8px !important;
  margin-left: 8px;
}

ul.recharts-default-legend {
  margin: 10px !important;
}

.MuiExpansionPanel-expanded-535 {
  margin: 0 !important;
}

a:-webkit-any-link {
  cursor: pointer;
  text-decoration: none;
}

.multiple-date-input {
  color: white;
  background: transparent;

  font-size: 16px;
  width: 100%;
  border: '10px solid red';
  min-height: 8px;
  min-width: 8px;
}

/* .MuiFormLabel-root.Mui-focused {
  color: rgb(255,255,255) !important
} */
/* Chrome, Safari, Edge, Opera */
/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

/*
input[type=number] {
  -moz-appearance: textfield;
} */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 9999s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
  caret-color: #fff !important;
  font-size: inherit;
}
.blink_me {
  animation: blinker 1s linear;
  animation-iteration-count: 3;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
